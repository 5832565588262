import React from 'react'
import { Root } from '../components/Root'
import { Button } from '../ui/buttons'
import { Card } from '../ui/cards'
import { url } from '../urls'

const OurMission = () => (
  <Root>
    <h2>Our Mission</h2>
    <Card>
      <p>
        The sole purpose of the Pacific Northwest Hospitals and Institutions
        committee is to carry the message of Alcoholics Anonymous to persons
        confined in any facility, government or private. All A.A. panel meetings
        of this Committee are to be conducted at the invitation of each
        facility.
      </p>
      <p>
        The Committee is created under The Ninth Tradition of A.A.: A.A. as
        such, ought never be organized; but we may create service boards or
        committees directly responsible to those they serve. The Committee as
        such is a service board operating under The Twelve Traditions of
        Alcoholics Anonymous in all of its affairs, ever mindful of the
        admonition cooperation not affiliation.
      </p>
      <p>
        The Committee is not a thing apart from A.A., but pursuant to its
        existing services. The Committee guarantees continuity of the A.A.
        panels. No one individual can make that possible, only the coordinating
        continuity of a committee can. Members come and members go, but the
        committee continues.
      </p>
      <Button to={url.getInvolved}>Get Involved</Button>
    </Card>
  </Root>
)

export default OurMission
